<div class="flex h-screen items-center justify-center">
  <div class="card w-96 bg-base-100 shadow-xl">
    <div class="card-body">
      <h2 class="card-title text-center">Login</h2>
      <div>
        <div class="form-control">
          <label class="label">
            <span class="label-text">Email</span>
          </label>
          <input [(ngModel)]="email" type="text" placeholder="Enter your email" class="input input-bordered" />
        </div>
        <div class="form-control mt-4">
          <label class="label">
            <span class="label-text">Password</span>
          </label>
          <input [(ngModel)]="password" type="password" placeholder="Enter your password" class="input input-bordered" />
        </div>
        <div class="form-control mt-6">
          <button (click)="login()" class="btn btn-primary">Login</button>
        </div>
      </div>
    </div>
  </div>
</div>
