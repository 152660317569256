import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import {
  AudioCompressionService,
  CompressionOptions,
  CompressionResult,
  CompressionStatus,
  CompressionSuccess,
  CompressionInProgress
} from './audio-compression-service';

@Injectable()
export class MockAudioCompressor implements AudioCompressionService {
  async init(): Promise<boolean> {
    return true;
  }

  compressWithProgress(filename: string, blob: Blob, options: CompressionOptions): Observable<CompressionResult> {
    const compressing: CompressionInProgress = { status: CompressionStatus.compressing, progress: 80, elapsedTime: 0  };

    const done: CompressionSuccess = { status: CompressionStatus.done, result: blob };

    return of(compressing, done);
  }

  getDuration(filename: string, file: Blob): Observable<number> {
    return of(80);
  }
}
