import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NewProjectWizardService } from '../../modules/new-project/services/new-project-wizard.service';
import { ProjectDetailsComponent } from '../../modules/new-project/steps/project-details/project-details.component';
import { ProjectPaymentComponent } from "../../modules/new-project/steps/project-payment/project-payment.component";
import { ProjectFileUploadComponent } from '../../modules/new-project/steps/project-file-upload/project-file-upload.component';
import { AppResultComponent } from "../../components/app-result/app-result.component";

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    ProjectDetailsComponent,
    ProjectPaymentComponent,
    ProjectFileUploadComponent,
    AppResultComponent
]
})
export class CreateProjectComponent implements OnInit {

  get step$() {
    return this.newProjectWizard.currentStep$;
  }

  constructor(
    public newProjectWizard: NewProjectWizardService
  ) { }

  ngOnInit(): void {
    this.newProjectWizard.initialize();
  }

  reset(): void {
    this.newProjectWizard.reset();
  }
}
