/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TranscriptionJobDTO { 
    status?: TranscriptionJobDTO.StatusEnum;
    createdAt?: string;
}
export namespace TranscriptionJobDTO {
    export type StatusEnum = 'PENDING' | 'PROCESSING' | 'COMPLETED' | 'FAILED';
    export const StatusEnum = {
        Pending: 'PENDING' as StatusEnum,
        Processing: 'PROCESSING' as StatusEnum,
        Completed: 'COMPLETED' as StatusEnum,
        Failed: 'FAILED' as StatusEnum
    };
}


