import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranscriptionResultComponent } from "../../components/transcription-result/transcription-result.component";
import { ProjectControllerService, ProjectResultsDTO, SummaryResponseDTO } from '../../../api_ts_sdk';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, catchError, map, Observable, Subscription, tap, of } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-results',
  standalone: true,
  imports: [TranscriptionResultComponent, CommonModule],
  templateUrl: './results.component.html',
  styleUrl: './results.component.css'
})
export class ResultsComponent implements OnInit, OnDestroy {

  readonly loading$ = new BehaviorSubject<boolean>(true);
  readonly error$ = new BehaviorSubject<string | null>(null);


  private transcription = new BehaviorSubject('');
  readonly transcription$ = this.transcription.asObservable();

  private summary = new BehaviorSubject<Required<SummaryResponseDTO>>({ keyPoints: [], summary: '' });
  readonly summary$ = this.summary.asObservable();

  private projectId$: Observable<string>;

  private sub: Subscription | undefined;

  constructor(
    private projectController: ProjectControllerService,
    private route: ActivatedRoute,
  ) {
    this.projectId$ = this.route.paramMap.pipe(
      map(params => params.get('projectId') as string)
    );
  }

  ngOnInit(): void {
    this.sub = this.projectId$.subscribe({
      next: (projectId) => this.loadProjectResults(projectId)
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  loadProjectResults(projectId: string) {
    this.loading$.next(true);
    this.error$.next(null);

    this.projectController
      .getResults(Number(projectId))
      .pipe(
        tap(() => this.loading$.next(false)),
        tap((results) => this.updateResults(results)),
        catchError(error => {
          console.log(error)
          this.loading$.next(false);
          this.error$.next('Failed to load transcription results. Please try again later.');
          return of(null);
        })
      )
      .subscribe();
  }

  updateResults(results: ProjectResultsDTO | null): void {
    if (!results) return;
    this.transcription.next(results.transcription!);
    this.summary.next(results.summaryResult! as Required<SummaryResponseDTO>);
  }
}
