import { Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { LandingComponent } from './pages/landing/landing.component';
import { SignupComponent } from './pages/signup/signup.component';
import { authGuard } from './guards/auth.guard';
import { CreateProjectComponent } from './pages/create-project/create-project.component';
import { ResultsComponent } from './pages/results/results.component';
import { HomeComponent } from './modules/home/home.component';

export const routes: Routes = [
  { path: 'landing', component: LandingComponent },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  {
    canActivate: [authGuard],
    path: 'app',
    component: HomeComponent,
    children: [
      { path: 'projects/new', component: CreateProjectComponent },
      { path: 'projects/:projectId/results', component: ResultsComponent },
      { path: '', redirectTo: 'projects/new', pathMatch: 'full' }
    ],
  },
  { path: '', redirectTo: '/app', pathMatch: 'full' }
];
