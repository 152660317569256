<div class="container">
  <nav>
    <div class="logo">Tito</div>
    <button class="nav-button">🎧</button>
  </nav>

  <main>
    <div>
      <div class="blur-effect" style="top: -20px; left: -20px;"></div>
      <h1>
        Habla.<br>
        Escribe.<br>
        <span class="accent">Crea.</span>
      </h1>
      <p class="subtitle">Transcripción mágica para tus ideas 🎧✨</p>
      <div class="button-group">
        <button (click)="goToLogin()" class="button primary">✨ Empezar ahora</button>
        <button class="button secondary">Ver demo</button>
      </div>
    </div>

    <div class="interface-mock">
      <div class="mock-header">
        <div class="window-dots">
          <div class="dot" style="background: #F87171;"></div>
          <div class="dot" style="background: #FBBF24;"></div>
          <div class="dot" style="background: #34D399;"></div>
        </div>
        <div>🔊</div>
      </div>
      <div class="mock-content">
        <div class="mock-line" style="width: 75%;"></div>
        <div class="mock-line" style="width: 50%;"></div>
        <div class="mock-line" style="width: 85%;"></div>
      </div>
    </div>
  </main>
</div>
