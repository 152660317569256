/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ProjectDTO { 
    id?: number;
    createdAt?: string;
    name?: string;
    state?: ProjectDTO.StateEnum;
}
export namespace ProjectDTO {
    export type StateEnum = 'AWAITING_PAYMENT' | 'AWAITING_FILES' | 'FILES_UPLOADED' | 'PROCESSING_FILES' | 'TRANSCRIBING' | 'SUMMARIZING' | 'DONE';
    export const StateEnum = {
        AwaitingPayment: 'AWAITING_PAYMENT' as StateEnum,
        AwaitingFiles: 'AWAITING_FILES' as StateEnum,
        FilesUploaded: 'FILES_UPLOADED' as StateEnum,
        ProcessingFiles: 'PROCESSING_FILES' as StateEnum,
        Transcribing: 'TRANSCRIBING' as StateEnum,
        Summarizing: 'SUMMARIZING' as StateEnum,
        Done: 'DONE' as StateEnum
    };
}


