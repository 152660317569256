/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PaymentDTO { 
    createdAt?: string;
    completedAt?: string;
    status?: PaymentDTO.StatusEnum;
    amount?: number;
}
export namespace PaymentDTO {
    export type StatusEnum = 'SENT_TO_PROCESSOR' | 'APPROVED' | 'REJECTED' | 'CANCELED' | 'CHARGED_BACK';
    export const StatusEnum = {
        SentToProcessor: 'SENT_TO_PROCESSOR' as StatusEnum,
        Approved: 'APPROVED' as StatusEnum,
        Rejected: 'REJECTED' as StatusEnum,
        Canceled: 'CANCELED' as StatusEnum,
        ChargedBack: 'CHARGED_BACK' as StatusEnum
    };
}


