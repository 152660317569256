import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AuthControllerService } from '../../../api_ts_sdk';
import { SessionService } from '../../services/session.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent {
  email: string = '';

  password: string = '';

  constructor(
    private sessionService: SessionService,
    private router: Router
  ) { }

  login() {
    this.sessionService
      .login(this.email, this.password)
      .subscribe({
        next: () => this.router.navigate(['app', 'projects', 'new']),
        error: (error) => {
          console.log('Login failed:', error);
        }
      });
  }
}
