<!-- project-file-row.component.html -->
<div class="receipt-row" [ngClass]="{'error': cost!.state === 'error'}">
  <div class="receipt-content">
    <span class="file-name" [title]="cost!.filename">
      {{ cost!.filename }}
    </span>

    <div class="cost-section">
      <!-- Loading State -->
      @if (cost!.state === 'loading') {
      <span class="loading loading-dots loading-xs max-w-4"></span>
      }

      <!-- Error State -->
      @if (cost!.state === 'error') {
      <ng-container>
        <span class="cost">-</span>
      </ng-container>
      }

      <!-- Success State -->
      @if (cost && cost.state === 'success' && cost.estimate !== undefined) {
      <ng-container>
        <span class="cost">{{ cost.estimate.cost| currency }}</span>
      </ng-container>
      }

      @if (allowDeleting) {
      <button class="delete-btn" (click)="onDeleteRowTapped(cost!.filename || '')"
        [title]="'Remove ' + (cost!.filename || 'file')">
        ❌
      </button>
      }
    </div>
  </div>

  <!-- Error Message -->
  @if (cost!.state === 'error') {
  <div class="error-message">
    Failed to calculate cost. Please check the file format.
  </div>
  } @else if (cost!.state === 'success') {
    <span class="text-slate-500">{{cost?.estimate?.durationInSeconds! | timeFormatter}}</span>
  }
</div>
