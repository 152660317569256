import { Injectable } from '@angular/core';
import { HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, from, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap } from 'rxjs/operators';
import { FileStatus } from '../model/file-status';
import { AudioCompressionService, CompressionResult, CompressionStatus } from '../../../services/audio-compression/audio-compression-service';
import { ProjectControllerService } from '../../../../api_ts_sdk';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  private filesSubject = new BehaviorSubject<FileStatus[]>([]);
  filesUploads$ = this.filesSubject.asObservable();

  constructor(
    private compressor: AudioCompressionService,
    private projectController: ProjectControllerService
  ) {}

  uploadFiles(projectId: number, files: File[]): Observable<FileStatus> {
    this.initializeFiles(files);
    return this.processFiles(projectId, files);
  }

  reset() {
    this.filesSubject.next([]);
  }

  private initializeFiles(files: File[]) {
    const initialStatuses = files.map(file => this.createInitialStatus(file));
    this.filesSubject.next(initialStatuses);
  }

  private createInitialStatus(file: File): FileStatus {
    return { filename: file.name, state: 'pending' };
  }

  private processFiles(projectId: number, files: File[]) {
    return from(files).pipe(
      concatMap(file => this.processFile(projectId, file))
    );
  }

  private processFile(projectId: number, file: File): Observable<FileStatus> {
    return this.compressor.compressWithProgress(file.name, file, {})
      .pipe(
        tap((event) => {
          if (event.status === CompressionStatus.compressing) {
            this.updateFileStatus(file.name, { state: 'compressing', progress: event.progress })
          }
        }),
        mergeMap(compressionResult => {
          return this.handleCompression(projectId, file.name, compressionResult)
        }),
        map(event => {
          return this.handleUploadEvent(file.name, event)
        }),
        catchError(error => this.handleError(file.name, error))
      );
  }

  private handleCompression(projectId: number, filename: string, compressionResult: CompressionResult): Observable<HttpEvent<any> | null> {
    if (compressionResult.status === 'error') {
      throw new Error(compressionResult.detail);
    }

    if (compressionResult.status === 'done') {
      const blob = compressionResult.result;
      this.updateFileStatus(filename, { state: 'uploading', progress: 0, sizeInBytes: blob.size });
      return this.projectController.uploadFile(
        projectId,
        filename,
        compressionResult.result,
        'events',
        true
      );
    }

    return EMPTY;
  }

  private handleUploadEvent(filename: string, event: HttpEvent<any> | null): FileStatus {
    if (!event) {
      return { filename, state: 'compressing' };
    }

    if (event.type === HttpEventType.UploadProgress) {
      const progress = this.calculateProgress(event as HttpProgressEvent);
      this.updateFileStatus(filename, { progress });
      return { filename, state: 'uploading', progress };
    }

    if (event.type === HttpEventType.Response) {
      this.updateFileStatus(filename, { state: 'complete' });
      return { filename, state: 'complete' };
    }

    return { filename, state: 'uploading' };
  }

  private calculateProgress(event: HttpProgressEvent): number {
    return Math.round((event.loaded * 100) / event.total!);
  }

  private handleError(filename: string, error: Error): Observable<FileStatus> {
    const errorStatus: FileStatus = {
      filename,
      state: 'error',
      error: error.message
    };
    this.updateFileStatus(filename, errorStatus);
    return of(errorStatus);
  }

  private updateFileStatus(filename: string, status: Partial<FileStatus>) {
    const currentFiles = this.filesSubject.value;
    const fileIndex = currentFiles.findIndex(f => f.filename === filename);

    if (fileIndex !== -1) {
      const updatedFiles = [...currentFiles];
      updatedFiles[fileIndex] = {
        ...updatedFiles[fileIndex],
        ...status
      };
      this.filesSubject.next(updatedFiles);
    }
  }
}
