@switch (step$ | async) {
  @case ('loading') {
  <div class="flex flex-col items-center gap-2 bg-white rounded-xl shadow-xl px-8 py-4">
    <span class="loading loading-spinner loading-md"></span>
    <span class="text-sm">Cargando</span>
  </div>
  }
  @case ('selectingFiles') {
  <app-project-details class="w-2/3" />
  }
  @case ('aboutToPay') {
  <app-project-payment class="w-2/3" />
  }
  @case ('uploadingFiles') {
  <app-project-file-upload class="w-2/5 min-w-2/5" />
  }
  @case ('done') {
  <app-app-result
    title="¡Tus archivos fueron cargados!"
    body="Están siendo procesados, te mandaremos un mail cuando todo esté listo"
    buttonLabel="Volver al principio"
    [isError]="false"
    (onButtonClick)="reset()" />
  }
  }
