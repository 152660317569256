import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'megabytes',
  standalone: true
})
export class MegabytesPipe implements PipeTransform {
  transform(bytes: number): string {
    if (isNaN(bytes) || bytes < 0) {
      return '0.0';
    }

    const mb = Math.round(bytes / (1024 * 1024));

    return mb.toString();
  }
}
