import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CostListComponent } from '../../components/cost-list/cost-list.component';
import { MercadoPagoComponent } from '../../../../components/payment-form/mercado-pago/mercado-pago.component';
import { NewProjectWizardService } from '../../services/new-project-wizard.service';
import { PagoHostComponent } from "../../../../components/payment-form/payment-form.component";
import { PaymentForm } from '../../../../components/payment-form/model/payment-form.interface';

@Component({
  selector: 'app-project-payment',
  standalone: true,
  imports: [
    CommonModule,
    CostListComponent,
    ReactiveFormsModule,
    MercadoPagoComponent,
    PagoHostComponent
],
  templateUrl: './project-payment.component.html',
  styleUrl: './project-payment.component.css'
})
export class ProjectPaymentComponent {

  get status$() {
    return this.projectWizardState.paymentStatus$;
  }

  get costs$() {
    return this.projectWizardState.costs$;
  }

  get totalCost() {
    return this.projectWizardState.totalCost;
  }

  get preferenceId() {
    return this.projectWizardState.paymentPreferenceId!;
  }

  constructor(
    public projectWizardState: NewProjectWizardService,
  ) {}

  back(): void {
    this.projectWizardState.goBack();
  }

  onPaymentSubmit(form: PaymentForm) {
    this.projectWizardState.payAndAdvance(form)
      .subscribe(() => {});
  }
}

export enum ProjectPaymentComponentStatus {
  awaitingPayment = 'awaitingPayment',
  checkingPayment = 'checkingPayment',
  confirmed = 'confirmed',
}
