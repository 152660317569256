<ol class="w-96 bg-base-100 m-4 rounded-md flex flex-col gap-2 overflow-auto">
  @switch (projectsState$ | async) {
  @case ('loading') {
    <span class="loading loading-spinner loading-md"></span>
  }
  @case ('success') {
  <li class="mx-4 mt-4 font-medium">Recientes</li>
  @for (project of (projects$ | async); track project.id) {
    <app-project-list-item [project]="project" (projectClicked)="goToProject($event)"/>
  }
  }
  @case ('failure') {
    <span>Hubo un problema al cargar los proyectos</span>
  }
  }
</ol>
<div class="w-full flex justify-center items-center">
  <router-outlet />
</div>
