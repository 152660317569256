import { Observable } from "rxjs";

export type CompressionResult = CompressionSuccess | CompressionInProgress | CompressionFailure

export interface CompressionSuccess {
  status: CompressionStatus.done
  result: Blob
}

export interface CompressionInProgress {
  status: CompressionStatus.compressing
  /**
   * Compression progress as a number between 0 and 1, where 1 means "done".
   */
  progress: number

  elapsedTime: number
}

export interface CompressionFailure {
  status: CompressionStatus.error,
  detail: string
}

export enum CompressionStatus {
  compressing = 'compressing',
  done = 'done',
  error = 'error',
}

export interface CompressionOptions {
  channels?: '1' | '2',
  bitrate?: '16k',
  sampleRate?: '24000'
  format?: 'mp3'
}

export abstract class AudioCompressionService {
  abstract init(): Promise<boolean>

  abstract compressWithProgress(filename: string, blob: Blob, options: CompressionOptions): Observable<CompressionResult>;

  abstract getDuration(filename: string, blob: Blob): Observable<number>;
}
