<div class="bg-white rounded-xl shadow-xl">
  <div class="p-6 flex flex-col h-[620px]"> <!-- Fixed height container -->
    <h2 class="text-xl font-bold mb-4">Nueva transcripción</h2>

    <div class="flex gap-4 flex-1 min-h-0"> <!-- min-h-0 enables proper overflow -->
      <!-- Left side - Form -->
      <div class="flex flex-col w-2/5">
        <!-- Name Input Section -->
        <div class="form-control w-full">
          <label class="label">
            <span class="label-text">Título</span>
          </label>
          <input [formControl]="nameControl" type="text" placeholder="Proyectirijillo"
            class="input input-bordered w-full" [ngClass]="{
              'input-error': nameControl.invalid && nameControl.touched
            }">
          <!-- Error Messages -->
          <div class="min-h-[2rem]">
            <label class="label" *ngIf="nameControl.invalid && nameControl.touched">
              @switch (firstError$ | async) {
              @case ('required') {
              <span class="label-text-alt text-error">
                El título es obligatorio
              </span>
              }
              @case ('notWhiteSpaceOnly') {
              <span class="label-text-alt text-error">
                El título no puede contener solo espacio en blanco
              </span>
              }
              }
            </label>
          </div>
        </div>

        <!-- <label class="form-control">
          <div class="label">
            <span class="label-text">Descripción (opcional)</span>
          </div>
          <textarea class="textarea textarea-bordered h-24" placeholder="¿De qué hablan tus audios? Esto podría mejorar los resultados"></textarea>
          <div class="label">
            <span class="label-text-alt ml-auto">0/150</span>
          </div>
        </label> -->

        <!-- File Drop Zone -->
        <div
          class="flex flex-1 flex-col items-center justify-center p-8 border-2 border-dashed border-base-300 rounded-lg transition-colors duration-300 hover:border-primary cursor-pointer"
          (dragover)="onDragOver($event)" (drop)="onDrop($event)">
          <span class="text-center text-base-content/70">Arrastra tus archivos aquí o presiona el botón</span>
          <div class="mt-4">
            <input type="file" multiple (change)="onFileSelect($event)" class="hidden" #fileInput>
            <button class="btn btn-primary" (click)="fileInput.click()">
              Seleccionar
            </button>
          </div>
        </div>
      </div>

      <!-- Right side - File list -->
      <div class="flex flex-col w-3/5 min-h-0"> <!-- min-h-0 ensures proper flex behavior -->
        <span>Archivos</span>
        <div class="flex-1 min-h-0"> <!-- Container for app-cost-list -->
          <app-cost-list class="h-full flex flex-col" [costs]="costs$ | async">
          </app-cost-list>
        </div>
      </div>
    </div>

    <div class="flex mt-4 pt-4 border-t">
      <button (click)="continue()" [disabled]="loading || !(canContinue$ | async)" class="btn btn-primary ml-auto">
        Continuar
        @if (loading) {
          <span class="loading loading-spinner loading-md"></span>
        }
      </button>
    </div>
  </div>
</div>
