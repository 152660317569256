/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FileToBeUploadedDTO } from './fileToBeUploadedDTO';


export interface PatchProjectDTO { 
    projectStatus?: PatchProjectDTO.ProjectStatusEnum;
    files?: Array<FileToBeUploadedDTO>;
}
export namespace PatchProjectDTO {
    export type ProjectStatusEnum = 'AWAITING_PAYMENT' | 'AWAITING_FILES' | 'FILES_UPLOADED' | 'PROCESSING_FILES' | 'TRANSCRIBING' | 'SUMMARIZING' | 'DONE';
    export const ProjectStatusEnum = {
        AwaitingPayment: 'AWAITING_PAYMENT' as ProjectStatusEnum,
        AwaitingFiles: 'AWAITING_FILES' as ProjectStatusEnum,
        FilesUploaded: 'FILES_UPLOADED' as ProjectStatusEnum,
        ProcessingFiles: 'PROCESSING_FILES' as ProjectStatusEnum,
        Transcribing: 'TRANSCRIBING' as ProjectStatusEnum,
        Summarizing: 'SUMMARIZING' as ProjectStatusEnum,
        Done: 'DONE' as ProjectStatusEnum
    };
}


