<div class="w-full max-w-4xl mx-auto">
  <div class="flex items-center justify-between mb-6">
    <h1 class="text-2xl font-semibold">Resultados</h1>
    <div class="flex items-center gap-2 border rounded-lg p-1">
      <button (click)="showRaw = false" class="flex items-center gap-2 px-4 py-2 rounded-md"
        [class.bg-base-200]="!showRaw">
        <i-lucide [img]="ListIcon" class="w-4 h-4" />
        <span>Resumen</span>
      </button>
      <button (click)="showRaw = true" class="flex items-center gap-2 px-4 py-2 rounded-md"
        [class.bg-base-200]="showRaw">
        <i-lucide [img]="FileTextIcon" class="w-4 h-4" />
        <span>Transcripción</span>
      </button>
    </div>
  </div>

  @if (showRaw) {
  <div class="bg-base-100 rounded-lg border p-6 shadow-sm">
    <markdown [data]="rawTranscription"></markdown>
  </div>
  } @else {
  <div class="bg-base-100 rounded-lg border p-6 shadow-sm">
    <div class="space-y-4">
      <div>
        <div class="border-b pb-4">
          <h2 class="text-lg font-medium mb-2">Puntos Clave</h2>
          <ul class="list-disc list-inside space-y-2">
            @for (point of summary.keyPoints; track point) {
            <li>{{ point }}</li>
            }
          </ul>
        </div>

        <h2 class="text-lg font-medium mt-4 mb-2">Resumen</h2>
        <markdown [data]="summary.summary"></markdown>
      </div>

      <!-- <div class="border-b pb-4">
          <h2 class="text-lg font-medium mb-2">Action Items</h2>
          <ul class="list-disc list-inside space-y-2 text-base-content/70">
            @for (item of summary.actionItems; track item) {
              <li>{{ item }}</li>
            }
          </ul>
        </div> -->

      <!-- <div>
          <h2 class="text-lg font-medium mb-2">Meeting Participants</h2>
          <div class="flex flex-wrap gap-2">
            @for (participant of summary.participants; track participant) {
              <span class="px-3 py-1 bg-base-200 rounded-full text-sm">
                {{ participant }}
              </span>
            }
          </div>
        </div> -->
    </div>
  </div>
  }
</div>
