import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ProjectDTO } from "../../../../api_ts_sdk";

@Component({
  selector: 'app-project-list-item',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './project-list-item.component.html',
})
export class ProjectListItemComponent {
  @Input() project?: ProjectDTO;

  @Output() projectClicked = new EventEmitter<ProjectDTO>();

  get status(): string | null {
    switch (this.project!.state!) {
      case ProjectDTO.StateEnum.AwaitingPayment:
        return "Esperando pago";
      case ProjectDTO.StateEnum.AwaitingFiles:
        return "Archivos pendientes";
      case ProjectDTO.StateEnum.FilesUploaded:
      case ProjectDTO.StateEnum.ProcessingFiles:
      case ProjectDTO.StateEnum.Transcribing:
      case ProjectDTO.StateEnum.Summarizing:
        return "Procesando";
      case ProjectDTO.StateEnum.Done:
        return null;
      default:
        return "Estado desconocido"
    }
  }

  get isInWarningStatus(): boolean {
    switch (this.project!.state!) {
      case ProjectDTO.StateEnum.AwaitingPayment:
      case ProjectDTO.StateEnum.FilesUploaded:
      case ProjectDTO.StateEnum.ProcessingFiles:
      case ProjectDTO.StateEnum.Transcribing:
      case ProjectDTO.StateEnum.Summarizing:
      case ProjectDTO.StateEnum.Done:
        return false;
      case ProjectDTO.StateEnum.AwaitingFiles:
      default:
        return true
    }
  }

  onClick() {
    this.projectClicked.emit(this.project!);
  }
}
