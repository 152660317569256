import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PaymentForm } from '../model/payment-form.interface';
import { BasePaymentForm } from '../base-payment-form';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-mock-checkout-bricks',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './mock-checkout-bricks.component.html',
  styleUrl: './mock-checkout-bricks.component.css'
})
export class MockCheckoutBricksComponent extends BasePaymentForm {
  @Input() override preferenceId: string | null = null;
  @Input() override amount: number | null = null;
  @Output() override onSubmit = new EventEmitter<PaymentForm>();

  cardNumber: string = '';
  expiry: string = '';
  cvv: string = '';
  cardholderName: string = '';

  handleSubmit() {
    const paymentForm: PaymentForm = {
      selectedPaymentMethod: 'credit-card',
      formData: {
        'token': 'abc',
        'issuer_id': 'pepito',
        'payment_method_id': '1',
        'transaction_amount': 1000,
        'payment_method_option_id': null,
        'processing_mode': null,
        'installments': 1,
        'payer': {
          'email': 'delfinrosa@gmail.com',
          'identification': {
            'type': 'DNI',
            'number': '12345678'
          }
        }
      }
    }

    this.onSubmit.emit(paymentForm);
  }
}
