<div class="w-full bg-white rounded-xl shadow-xl h-[600px]">
  <div class="p-6 flex flex-col h-[600px]"> <!-- Fixed height container -->
    <h2 class="text-xl font-bold mb-4">Subiendo archivos</h2>

    <div class="flex flex-col flex-1 min-h-0"> <!-- min-h-0 enables proper overflow -->
      <span>No vayas a cerrar la pestaña!</span>
      <div class="flex-1 min-h-0">

        <div class="flex flex-col h-full">
          <div class="flex-1 overflow-y-auto min-h-0"> <!-- Scrollable container -->
            @for (file of filesUploads$ | async; track file.filename) {
            <app-file-upload-progress [file]="file" />
            }
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
