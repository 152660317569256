<div class="upload-row" [ngClass]="{'error': file.state === 'error'}">
  <div class="upload-content">
    <span class="file-name" [title]="file.filename">{{ file.filename }}</span>

    <div class="progress-section">
      @if (file.state === 'uploading') {
      <div class="w-full bg-base-300 rounded-full h-2.5">
        <div class="bg-primary h-2.5 rounded-full transition-all duration-300" [style.width.%]="file.progress">
        </div>
      </div>
      }
      @if (file.state === 'complete') {
      <div class="text-success ml-auto">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
        </svg>
      </div>
      }
      @if (file.state === 'compressing') {
      <div class="ml-auto">
        <span>Comprimiendo</span>
        <div class="w-full bg-base-300 rounded-full h-2.5">
          <div class="bg-primary h-2.5 rounded-full transition-all duration-300" [style.width.%]="file.progress">
          </div>
        </div>
      </div>
      }
      @if (file.state === 'error') {
      <div class="text-error ml-auto">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>
      }
    </div>
  </div>

  @if (file.state !== 'error' && file.sizeInBytes) {
  <span class="text-slate-500">{{file.sizeInBytes | megabytes}} MB</span>
  }

  @if (file.state === 'error') {
  <div class="error-message">
    {{ file.error || 'Error al cargar el archivo' }}
  </div>
  }
</div>
