<div class="w-full max-w-[1440px] mx-auto px-4 py-8">
  @if (loading$ | async) {
    <div class="flex justify-center items-center min-h-[200px]">
      <div class="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
    </div>
  } @else if (error$ | async) {
    <div class="text-red-500 text-center p-4 bg-red-50 rounded-lg">
      {{ error$ }}
    </div>
  } @else {
    <app-transcription-result 
      [rawTranscription]="(transcription$ | async)!" 
      [summary]="(summary$ | async)!" 
    />
  }
</div>
