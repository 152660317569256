<div class="flex flex-col h-full">
  @if (costs && costs.length > 0) {
  <div class="flex-1 overflow-y-auto min-h-0"> <!-- Scrollable container -->
    @for (cost of costs; track cost.filename) {
    <app-project-file-row [cost]="cost" [allowDeleting]="allowDeleting" />
    }
  </div>
  <div class="flex justify-between mt-4 pt-4 border-t"> <!-- Footer stays at bottom -->
    <span class="font-bold">TOTAL</span>
    <span class="font-bold">{{total | currency}}</span>
  </div>
  } @else {
  <div class="flex-1 flex justify-center items-center min-h-0"> <!-- Scrollable container -->
    <p class="text-gray-500">Aún no cargaste ningún archivo</p>
  </div>
  }
</div>
