import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, filter, map, take, tap } from "rxjs";
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';
import { environment } from "../../environments/environment";

const MULTITHREADED_CONFIG = {
  coreURL: '/assets/ffmpeg/ffmpeg-core.js',
  wasmURL: '/assets/ffmpeg/ffmpeg-core.wasm',
  workerURL: '/assets/ffmpeg/ffmpeg-core.worker.js',
  classWorkerURL: '/assets/ffmpeg/worker.js'
};

export interface FfmpegProgressEvent {
  progress: number,
  time: number
}

@Injectable({
  providedIn: 'root'
})
export class FfmpegService {
  private initialized = false;
  private ffmpeg = new FFmpeg();
  private stdout = new BehaviorSubject<string>('');
  private progress = new BehaviorSubject<FfmpegProgressEvent>({ progress: 0, time: 0 });

  readonly messageLog$ = this.stdout.asObservable();

  async init(): Promise<boolean> {
    if (this.initialized) {
      return true;
    }

    this.ffmpeg.on('log', (event) => this.stdout.next(event.message));
    this.ffmpeg.on('progress', (event) => this.progress.next(event));

    if (environment.logThings) {
      this.stdout.subscribe({ next: console.log });
    }

    const ready = await this.ffmpeg.load(MULTITHREADED_CONFIG);
    this.initialized = ready;
    return ready;
  }

  async writeFile(filename: string, file: Blob): Promise<boolean> {
    const data = await fetchFile(file);
    return this.ffmpeg.writeFile(filename, data);
  }

  async readFile(filename: string): Promise<Uint8Array> {
    return this.ffmpeg.readFile(filename) as Promise<Uint8Array>;
  }

  async listFiles(): Promise<string[]> {
    const fsNodes = await this.ffmpeg.listDir('/');
    return fsNodes.map(node => node.name);
  }

  async deleteFile(filename: string): Promise<boolean> {
    return this.ffmpeg.deleteFile(filename);
  }

  async deleteFiles(filenames: string[]): Promise<boolean[]> {
    return Promise.all(filenames.map(f => this.deleteFile(f)));
  }

  async execCommand(args: string[]): Promise<number> {
    if (!this.ffmpeg.loaded) {
      throw new Error("FFmpeg is not initialized. Call init() first.");
    }
    return this.ffmpeg.exec(args);
  }

  getDurationFromStdout(): Observable<number> {
    // Create a new subscription for this specific duration request
    return this.stdout.pipe(
      filter(msg => msg.includes('Duration')),
      take(1),
      map(this.parseDuration)
    );
  }

  getProgress(): Observable<FfmpegProgressEvent> {
    this.progress.next({ progress: 0, time: 0 });
    return this.progress.pipe()
  }

  private parseDuration(durationString: string): number {
    const durationMatch = durationString.match(/Duration: (\d{2}):(\d{2}):(\d{2}.\d{2})/);
    if (!durationMatch) throw new Error("Could not determine audio duration");

    const [, hours, minutes, seconds] = durationMatch;
    return (
      parseInt(hours) * 3600 +
      parseInt(minutes) * 60 +
      parseFloat(seconds)
    );
  }
}
