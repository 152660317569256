import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FileStatus } from '../../model/file-status';
import { MegabytesPipe } from "../../../../utili/megabytes";

@Component({
  selector: 'app-file-upload-progress',
  standalone: true,
  imports: [CommonModule, MegabytesPipe],
  templateUrl: './file-upload-progress.component.html',
  styleUrl: './file-upload-progress.component.css'
})
export class FileUploadProgressComponent {
  @Input() file!: FileStatus;
}
