import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

/**
 * Revisa si control contiene solo espacio en blanco. Si es asi devuelve un error.
 */
export function notWhitespaceOnly(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (typeof control.value === 'string') {
      const trimmedValue = control.value.trim();

      if (trimmedValue.length === 0) {
        return {
          notWhiteSpaceOnly: { value: control.value }
        };
      }
    }

    return null;
  };
}
