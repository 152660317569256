import { environment } from "../../../environments/environment";
import { Mocks } from "../../../environments/mocks.enum";
import { AudioCompressionService } from "./audio-compression-service";
import { FfmpegAudioCompressor } from "./ffmpeg-audio-compressor";
import { MockAudioCompressor } from "./mock-audio-compressor";

export function audioCompressionFactory(): AudioCompressionService {
  if (environment.mocks.includes(Mocks.audioCompression)) {
    return new MockAudioCompressor();
  } else {
    return new FfmpegAudioCompressor();
  }
}
