<div class="flex gap-4">
  <div class="w-3/5 bg-white rounded-xl shadow-xl h-[600px]">
    <div class="p-6 flex flex-col h-[600px]"> <!-- Fixed height container -->
      <h2 class="text-xl font-bold mb-4">Nuevo proyecto</h2>

      <div class="flex flex-col flex-1 min-h-0"> <!-- min-h-0 enables proper overflow -->
        <span>Estás por pagar la transcripción de estos archivos</span>
        <div class="flex-1 min-h-0"> <!-- Container for app-cost-list -->
          <app-cost-list class="h-full flex flex-1 flex-col" [costs]="costs$ | async" [allowDeleting]="false">
          </app-cost-list>
        </div>
      </div>

      <div class="flex mt-4 pt-4 border-t">
        <button (click)="back()" class="btn">Volver</button>
      </div>
    </div>
  </div>

  @switch (status$ | async) {
    @case ('awaitingPayment') {
       <app-pago-dynamic class="w-3/5" [preferenceId]="preferenceId" [amount]="totalCost" (onSubmit)="onPaymentSubmit($event)"/>
    }
    @case ('checkingPayment') {
      <div class="flex items-center justify-center p-4 h-full">
        <div class="flex flex-col items-center gap-2 bg-white rounded-xl shadow-xl px-8 py-4">
          <span class="loading loading-spinner loading-md"></span>
          <span class="text-sm">Aguanta un ratito mientras se confirma el pago 💰</span>
        </div>
      </div>
    }
  }
</div>
