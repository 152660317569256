<div class="card w-96 bg-base-100 shadow-xl">
  <div class="card-body items-center text-center">
    <!-- Status Icon -->
    <div class="mb-4">
      @if (isError) {
      <i-lucide [img]="XCircleIcon" [size]="48" class="text-error" />
      } @else {
      <i-lucide [img]="CheckCircle2Icon" [size]="48" class="text-success" />
      }
    </div>

    <!-- Title -->
    <h2 class="card-title" [class]="isError ? 'text-error' : 'text-success'">
      {{ title }}
    </h2>

    <!-- Message Body -->
    <p class="text-base-content/80">{{ body }}</p>

    <!-- Action Button -->
    <div class="card-actions justify-center mt-4">
      <button class="btn text-white" [class]="isError ? 'btn-error' : 'btn-success'" (click)="onButtonClick.emit()">
        {{ buttonLabel }}
      </button>
    </div>
  </div>
</div>
