import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { ProjectDTO } from '../../../api_ts_sdk';
import { Observable } from 'rxjs';
import { ProjectRepository, RepositoryState } from '../../services/project.repository';
import { CommonModule } from '@angular/common';
import { ProjectListItemComponent } from './components/project-list-item.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [RouterOutlet, CommonModule, ProjectListItemComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
})
export class HomeComponent implements OnInit {

  readonly projectsState$: Observable<RepositoryState>;
  readonly projects$: Observable<ProjectDTO[]>;

  constructor(
    private projectRepository: ProjectRepository,
    private router: Router,
  ) {
    this.projectsState$ = this.projectRepository.state$;
    this.projects$ = this.projectRepository.projects$;
  }

  ngOnInit(): void {
    this.projectRepository.refreshProjects();
  }

  goToProject(project: ProjectDTO): void {
    this.router.navigate(['app', 'projects', project.id!, 'results'])
  }
}
