import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthControllerService, TokenDTO } from '../../api_ts_sdk';
import { UserDTO } from '../model/user.dto';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private jwtSubject: BehaviorSubject<string | null>;
  readonly jwt$: Observable<string | null>;

  private userSubject: BehaviorSubject<UserDTO | null>;
  readonly user$: Observable<UserDTO | null>;

  private readonly TOKEN_KEY = 'token';

  constructor(private auth: AuthControllerService) {
    this.jwtSubject = new BehaviorSubject<string | null>(this.getStoredToken());
    this.jwt$ = this.jwtSubject.asObservable();

    this.userSubject = new BehaviorSubject<UserDTO | null>(null);
    this.user$ = this.userSubject.asObservable();
  }

  private getStoredToken(): string | null {
    return localStorage.getItem(this.TOKEN_KEY);
  }

  public get currentJwt() {
    return this.jwtSubject.value;
  }

  public get currentUser() {
    return this.userSubject.value;
  }

  login(email: string, password: string): Observable<any> {
    return this.auth.autenticar({ identifier: email, password })
      .pipe(tap((dto) => this.storeToken(dto)))
      .pipe(tap((dto) => this.extractUser(dto)));
  }

  logout() {
    localStorage.removeItem(this.TOKEN_KEY);
    this.jwtSubject.next(null);
    this.userSubject.next(null);
  }

  isAuthenticated(): boolean {
    return !!this.getStoredToken();
  }

  private extractUser(dto: TokenDTO): void {
    const decoded = jwtDecode(dto.token!);

    const user: UserDTO = {
      email: decoded.jti!
    }

    this.userSubject.next(user);
  }

  private storeToken(dto: TokenDTO): void {
    localStorage.setItem(this.TOKEN_KEY, dto.token!);
    this.jwtSubject.next(dto.token!);
  }
}
