<div
  class="flex flex-col w-full"
  [ngClass]="{ 'h-full': loading }">
  <!-- Loading State -->
  <div *ngIf="loading" class="flex items-center justify-center p-4 h-full">
    <div class="flex flex-col items-center gap-2 bg-white rounded-xl shadow-xl px-8 py-4">
      <span class="loading loading-spinner loading-md"></span>
      <span class="text-sm">Cargando medios de pago</span>
    </div>
  </div>

  <!-- Error State -->
  <div *ngIf="error" class="alert alert-error mb-4">
    <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
    <span>{{ error }}</span>
  </div>

  <!-- MercadoPago Brick Container -->
  <div
    id="paymentBrick_container"
    class="flex-1 overflow-y-auto rounded-xl shadow-xl"
    [ngStyle]="{'min-height': loading ? '0' : '400px'}"
  ></div>

  <!-- [ngStyle]="{'min-height': loading ? '0' : '400px', 'display': loading ? 'none' : 'inherit'}" -->
</div>
