import { Injectable, OnInit } from '@angular/core';
import { ProjectControllerService, ProjectDTO } from '../../api_ts_sdk';
import { BehaviorSubject, catchError, concat, map, Observable, of, tap } from 'rxjs';

export type RepositoryState = 'success' | 'loading' | 'failure'

@Injectable({
  providedIn: 'root'
})
export class ProjectRepository implements OnInit {

  private projects = new BehaviorSubject<ProjectDTO[]>([]);
  readonly projects$ = this.projects.asObservable();

  private state = new BehaviorSubject<RepositoryState>('loading');
  readonly state$ = this.state.asObservable();

  constructor(
    private projectController: ProjectControllerService,
  ) { }

  ngOnInit(): void {
    this.refreshProjects();
  }

  /**
   * Recarga los proyectos desde el servidor, generalmente se usa una sola vez en
   * el arranque del servicio.
   */
  refreshProjects(): void {
    this.state.next('loading')

    this.projectController
      .getProjects()
      .pipe(
        tap(() => this.state.next('success')),
        map((projects) => sortProjectsByDate(projects, false))
      )
      .subscribe({
        next: result => this.projects.next(result),
        error: () => this.state.next('failure')
      });
  }

  add(newProject: ProjectDTO): void {
    const next = [newProject, ...this.projects.value];

    this.projects.next(next);
  }
}

function sortProjectsByDate(projects: ProjectDTO[], ascending: boolean = true): ProjectDTO[] {
  return projects.sort((a, b) => {
    const dateA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
    const dateB = b.createdAt ? new Date(b.createdAt).getTime() : 0;

    return ascending ? dateA - dateB : dateB - dateA;
  });
}
